import axios from "axios"
import { API_URL } from "../constants"

const http = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
})

http.interceptors.response.use(
  (response) => {
    if (response.config.url === "/user") {
      if (response.data.result.length > 0) {
        let aux = response.data.result.filter(
          (e) => e.email !== "" || e.fullName !== ""
        )
        let aux2 = response.data.result.filter(
          (e) => e.email === "" && e.fullName === ""
        )
        response.data.result = aux.concat(aux2)
      }
    } else if (response.config.url === "/user/admin/sentence") {
      if (response.data.result.length > 0) {
        let aux = response.data.result.sort(function (a, b) {
          return a.order - b.order
        })
        response.data.result = aux
      }
    }
    return response
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 403) {
      ;(() => {
        window.localStorage.removeItem("@TOKEN")
        window.location.href = "/"
      })()
    } else if (error.response.status === 500) {
      window.localStorage.removeItem("@TOKEN")
    }
    return Promise.reject(error.response)
  }
)

http.interceptors.request.use(
  (config) => {
    let token = null
    try {
      token = localStorage.getItem("@TOKEN")
    } catch (error) {
      //hanlde error
    }

    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default http
