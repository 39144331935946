import http from "./Middleware"


class Request {
  constructor(resource) {
    this.resource = resource
  }
  
  _getFormfromObject(object = {}) {
    const data = new window.FormData()
    
    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key)) {
        data.append(key, object[key])
      }
    }
    
    return data
  }
  
  async get(id) {
    try {
      const res = await http.get(`${this.resource}/${id}`)
      return res.data
    } catch (error) {
      throw error.data
    }
  }


  async getAll(params = {}) {
    try {
      const res = await http.get(this.resource, { params })
      return res.data || []
    } catch (error) {
      throw error.data
    }
  }

  async create(body) {
    try {
      const res = await http.post(this.resource, body)
      return res.data
    } catch (error) {
      throw error.data
    }
  }

  /**
   * @param form is a Object of a Form
   */
  async createForm(form) {
    return await this.create(this._getFormfromObject(form))
  }

  async update(body, id) {
    try {
      const res = await http.put(id ? `${this.resource}/${id}` : this.resource, body)
      return res.data
    } catch (error) {
      throw error.data
    }
  }

  /**
   * @param form is a Object of a Form
   */
  async updateForm(form) {
    return await this.update(this._getFormfromObject(form))
  }


  async delete(id) {
    try {
      const res = await http.delete(`${this.resource}/${id}`)
      return res.data
    } catch (error) {
      throw error.data
    }
  }
}

export default Request