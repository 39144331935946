export const API_URL = 'http://crizz.com.co:8082/'

export const ENDPOINTS = {
  LOGIN: '/user/admin/login',
  ADMINS: '/user/admin',
  USER: '/user',
  USERDETAILS: '/user/detail',
  USER_INFO: '/user/info',
  LANGUAGES: '/language',
  NOTIFICATIONS: '/user/admin/notification',
  SETTINGS: '/user/admin/configuration',
  PHRASES_OF_DAY: '/user/admin/sentence',
  DASHBOARD: '/dashboard',
  FINANCE:'/profits'
}
